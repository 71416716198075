import getAPI from '../../services/api';

import { AnyAction, Dispatch } from 'redux';

interface GetQuotesAction extends AnyAction {
  type: 'quotes/quotes';
  payload: any;
}

interface GetQuoteAction extends AnyAction {
  type: 'quotes/quote';
  payload: any;
}

interface GetQuoteCountAction extends AnyAction {
  type: 'quotes/quote_count';
  payload: any;
}


export const addQuote = (quote) => async (dispatch) => {
  const api = await getAPI();
  api.post('/quotes', quote).then((response) => {
    if (response.ok) {
      const { data } = response;
    }
  });
};

export const getQuote = (quoteId) => async (dispatch) => {
  dispatch({type: 'root/fetching', payload: true});
  const api = await getAPI();
  api.get(`/quotes/${quoteId}`).then((response) => {
    dispatch({type: 'root/fetching', payload: false});
    if (response.ok) {
      const { data } = response;
      if (data) {
        dispatch({ type: 'quotes/quote', payload: data });

      }
    }
  });
};

export const deleteQuote = (quoteId) => async (dispatch) => {
  dispatch({type: 'root/fetching', payload: true});
  const api = await getAPI();
  api.delete(`/quotes/${quoteId}`).then((response) => {
    dispatch({type: 'root/fetching', payload: false});
    if (response.ok) {
      // const { data } = response;
      window.location.reload();
    }
  });
};

// export const getQuotes = () => (dispatch: Dispatch<GetQuotesAction>) => {
export const getQuotes = () => {
  return async (dispatch) => {
    dispatch({type: 'root/fetching', payload: true});
    const api = await getAPI();
    api.get('/quotes').then((response) => {
      dispatch({type: 'root/fetching', payload: false});
      if (response.ok) {
        const { data } = response;
        if (data) {
          dispatch({ type: 'quotes/quotes', payload: data });
        }
      }
    });
  };
};

const getCreatedUpdated = (a) => {
  const notJSON = ['string', 'undefined'];
  let aUpdated = a.updated;
  if (!notJSON.includes(typeof aUpdated) && aUpdated !== null) {
    aUpdated = a.updated.S;
  }
  if (aUpdated) return aUpdated;

  let aCreated = a.created;
  if (!notJSON.includes(typeof aCreated) && aCreated !== null) {
    aCreated = a.created.S;
  }

  if (aCreated) return aCreated;
  return '2022-12-05T16:09:54.294Z';

};

const sortItems = (a: any, b: any) => {
  const dateA = new Date(getCreatedUpdated(a));
  const dateB = new Date(getCreatedUpdated(b));
  const timeA = dateA.getTime();
  const timeB = dateB.getTime();
  return timeB - timeA;
};

export const getPinnedQuotes = () => {
  return async (dispatch) => {
    dispatch({type: 'root/fetching', payload: true});
    const api = await getAPI();
    api.get('/pinned-quotes').then((response) => {
      dispatch({type: 'root/fetching', payload: false});
      if (response.ok) {
        const data: never[] = response.data as never[];
        if (data) {
          const sortedData = data.sort(sortItems);
          dispatch({ type: 'quotes/pinned_quotes', payload: sortedData });
        }
      }
    });
  };
};

export const getQuoteCount = () => {
  return async (dispatch: Dispatch<GetQuoteCountAction>) => {
    const api = await getAPI();
    api.get('/quotes/count').then((response) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          dispatch({ type: 'quotes/quote_count', payload: data });
        }
      }
    });
  };
};

