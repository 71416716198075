import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { addQuote } from '../../store/actions/quotes';

function AddQuote({
  showModal, closeModal, quote, editing, authors, sources, categories
}) {
  const dispatch = useDispatch();

  const [body, setBody] = useState('');
  const [author, setAuthor] = useState('');
  const [source, setSource] = useState('');
  const [category, setCategory] = useState('');
  const [pinned, setPinned] = useState(undefined);

  useEffect(() => {
    if (editing) {
      setBody(quote.body);
      setAuthor(quote.author);
      setSource(quote.source);
      setCategory(quote.category);
      setPinned(quote.pinned);
    } else {
      setBody('');
    }
  }, [editing]);

  const onSubmit = (event) => {
    event.preventDefault();
    let newQuote = {
      body,
      author: author || 'Anonymous',
      source: source || 'Personal Notes',
      category: category || 'General',
      pinned: pinned || undefined
    };

    const now = (new Date()).toISOString();
    if (editing) {
      newQuote = {
        ...newQuote,
        updated: now,
        quoteId: quote.quoteId,
        created: quote.created ? quote.created : now,

      };
      dispatch({type: 'quotes/edit_quote', payload: newQuote});

    } else {
      newQuote = {
        ...newQuote,
        created: now,
        quoteId: uuidv4(),
        updated: null,
      };
      dispatch({type: 'quotes/add_quote', payload: newQuote});
    }

    dispatch(addQuote(newQuote));
    if (pinned) {
      dispatch({type: 'quotes/did_pin_quote', payload: newQuote});
    } else {
      dispatch({type: 'quotes/did_unpin_quote', payload: newQuote});
    }

    setBody('');

    closeModal();
  };

  const [authorSuggestions, setAuthorSuggestions] = useState([]);
  const [categorySuggestions, setCategorySuggestions] = useState([]);
  const [sourceSuggestions, setSourceSuggestions] = useState([]);
  const [showAuthorSuggestions, setShowAuthorSuggestions] = useState(false);
  const [showCategorySuggestions, setShowCategorySuggestions] = useState(false);
  const [showSourceSuggestions, setShowSourceSuggestions] = useState(false);



  const filterSuggestions = (pre, opts) => {
    if (pre.length === 1) {
      return opts.filter((opt) => {
        return opt.toLowerCase().startsWith(pre.toLowerCase());
      });
    }
    return opts.filter((opt) => {
      return opt.toLowerCase().includes(pre.toLowerCase());
    });
  };

  const onChangeInput = (e, type) => {
    const input = e.target.value;
    if (type === 'author') {
      setShowAuthorSuggestions(true);
      setAuthor(input);
      setAuthorSuggestions(filterSuggestions(input, authors));
    }

    if (type === 'category') {
      setShowCategorySuggestions(true);
      setCategory(input);
      setCategorySuggestions(filterSuggestions(input, categories));
    }

    if (type === 'source') {
      setShowSourceSuggestions(true);
      setSource(input);
      setSourceSuggestions(filterSuggestions(input, sources));
    }

    if (type === 'pinned') {
      if (pinned == 'pinned') {
        setPinned(undefined);
      } else {
        setPinned('pinned');
      }
    }
  };

  const handleSuggestionClick = (suggestion, type) => {
    if (type === 'author') {
      setAuthor(suggestion);
      setAuthorSuggestions([]);
    }

    if (type === 'category') {
      setCategory(suggestion);
      setCategorySuggestions([]);
    }

    if (type === 'source') {
      setSource(suggestion);
      setSourceSuggestions([]);
    }
  };

  const hideAllSuggestions = () => {
    setShowAuthorSuggestions(false);
    setShowCategorySuggestions(false);
    setShowSourceSuggestions(false);
  };

  return (
    <Modal show={showModal} onHide={closeModal} onClick={() => {hideAllSuggestions(false);}}>
      <Modal.Header closeButton>
        <Modal.Title>{editing ? 'Update Quote' : 'New Quote'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="body">
            <Form.Label>Quote</Form.Label>
            <Form.Control as="textarea" rows={5} placeholder="Enter quote" value={body || ''} onChange={(e) => setBody(e.target.value)} required/>
          </Form.Group>

          <Form.Group className="mb-3" controlId="author">
            <Form.Label>Author</Form.Label>
            <Form.Control type="text" placeholder="Author" value={author || ''} onChange={(e) => { onChangeInput(e, 'author');}} />
            {author && showAuthorSuggestions && <div>
              {authorSuggestions.map((author) => (
                <div role='button' className='border p-1' key={author} onClick={() => {handleSuggestionClick(author, 'author');}}><small>{author}</small></div>
              ))}
            </div>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="source">
            <Form.Label>Source</Form.Label>
            <Form.Control type="text" placeholder="Source" value={source || ''} onChange={(e) => { onChangeInput(e, 'source');}} />
            {source  && showSourceSuggestions &&  <div>
              {sourceSuggestions.map((source) => (
                <div role='button' className='border p-1' key={source} onClick={() => {handleSuggestionClick(source, 'source');}}><small>{source}</small></div>
              ))}
            </div>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="category">
            <Form.Label>Category</Form.Label>
            <Form.Control type="text" placeholder="Category" value={category || ''} onChange={(e) => { onChangeInput(e, 'category');}} />
            {category  && showCategorySuggestions &&  <div>
              {categorySuggestions.map((category) => (
                <div role='button' className='border p-1' key={category} onClick={() => {handleSuggestionClick(category, 'category');}}><small>{category}</small></div>
              ))}
            </div>}
          </Form.Group>
          <Form.Group controlId="pinned" className="mb-3">
            <Form.Check
              type="checkbox"
              label="Pinned"
              onChange={(e) => { onChangeInput(e, 'pinned');}}
              checked={pinned === 'pinned'}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>

    </Modal>
  );
}

export default AddQuote;
