import React, {useState} from 'react';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Navbar, Nav, Container, Button, Offcanvas, Alert } from 'react-bootstrap';
import { Provider } from 'react-redux';
import ShowQuote from './components/ShowQuote/ShowQuote';
import PinnedQuotes from './components/PinnedQuotes/PinnedQuotes';

import configureStore from './store/config/configureStore';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import { awsconfig } from './config';

Amplify.configure(awsconfig);
const store = configureStore();
store.subscribe(() => {
  // console.log(store.getState());
});

type amplifier =  { signOut: any; user: any; }


function AlertDismissible() {
  const [showAlert, setShowAlert] = useState(true);


  return (
    <>
      <Alert show={showAlert} variant="success">
        <div className='d-flex justify-content-between'>
          <p>
        Welcome to Mind Mantle!
          </p>
          <div className="d-flex justify-content-end">
            <Button onClick={() => setShowAlert(false)} variant="outline-success">
            Got it!
            </Button>
          </div>
        </div>
        <p>
        Our app helps you collect and remember your favorite quotes, passages, and notes from books, podcasts, and more. With our unique approach of random recall, you can turn lessons into habits by keeping them fresh in your mind.
        </p>
        <p>
         Click the <i className="bi bi-plus-circle"></i>  button to add your first quote!
        </p>
      </Alert>
    </>
  );
}

function App({ signOut, user }: amplifier) {
  // console.log({cognito_user: user});
  // console.log(user.signInUserSession.idToken.jwtToken);
  const expand = false;
  const [showPinnedQuotesModal, setShowPinnedQuotesModal] = useState(false);
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  return (
    <Provider store={store}>
      <div className="container">
        <Navbar bg="light" expand={expand} className="mb-3" onToggle={() => { setNavbarExpanded(!navbarExpanded);}} expanded={navbarExpanded}>
          <Container>
            <Navbar.Brand href="/">Mind Mantle</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  {user.attributes.email}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="" onClick={() => { setShowPinnedQuotesModal(true); setNavbarExpanded(false);}}>Pinned Quotes</Nav.Link>
                  <Nav.Link href="" onClick={signOut}>Sign Out</Nav.Link>

                  {/* <Nav.Link href="#action2">Link</Nav.Link> */}
                  {/* <NavDropdown
                    title="Dropdown"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                    Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                    Something else here
                    </NavDropdown.Item>
                  </NavDropdown> */}
                </Nav>
                {/* <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success">Search</Button>
                </Form> */}
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        <AlertDismissible/>
        <ShowQuote />
        <PinnedQuotes
          showPinnedQuotesModal={showPinnedQuotesModal}
          closeModal={() => { setShowPinnedQuotesModal(false);}}/>
      </div>
    </Provider>
  );
}

export default withAuthenticator(App);
