// a library to wrap and simplify api calls
import { ApisauceInstance, create } from 'apisauce';
import { apiUrl, awsconfig } from '../config';
import { Auth } from 'aws-amplify';

Auth.configure(awsconfig);

// our "constructor"
// const create = (baseURL = 'http://10.0.2.2:3000/api') => {

const baseURL  = `${apiUrl}/Prod`;
// ------
// STEP 1
// ------
//
// Create and configure an apisauce-based api object.
//

const getJWTToken = async () => {
  const session = await Auth.currentSession();
  const jwtToken = session.getIdToken().getJwtToken();
  return jwtToken;
};

const api: ApisauceInstance = create({
  // base URL is read from the "constructor"
  baseURL,
  // here are some default headers
  headers: {
    'Cache-Control': 'no-cache',
  },
  // 10 second timeout...
  timeout: 10000,
});


api.addMonitor((response) => {
  if (response.problem) {
    console.log(response);
    // alert('Please check your network connection and try again');
    const option = window.confirm('Something went wrong :(. This may be a problem with your network or something on our end.\nWould you like to restart the application?');
    if (option) {
      // reset()
      window.location.reload();
    } else {
      // user clicked Cancel
      // do nothing or perform some other action
    }

  }
});

const getAPI = async () => {
  const jwtToken = await getJWTToken();
  api.setHeader('Authorization', `Bearer ${jwtToken}`);
  return api;
};

export default getAPI;
