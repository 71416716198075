import React, {useState, useEffect} from 'react';
import { Spinner, ListGroup } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { RootState } from '../../store/config/configureStore';
import { getPinnedQuotes } from '../../store/actions/quotes';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Modal } from 'react-bootstrap';


function PinnedQuotes({showPinnedQuotesModal, closeModal}) {
  const dispatch = useAppDispatch();
  const pinnedQuotes = useAppSelector((state: RootState) => state.quotes.pinnedQuotes);

  useEffect(() => {
    dispatch(getPinnedQuotes());
  }, []);
  console.log(pinnedQuotes);
  return (
    <Modal show={showPinnedQuotesModal}  onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Pinned Quotes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup defaultActiveKey="#link1">
          {pinnedQuotes.map((quote: any) => {
            return (<ListGroup.Item action onClick={() => {
              dispatch({ type: 'quotes/quote', payload: quote });
              closeModal();
            }} key={quote.quoteId}>
              <div className="pinned-quote-text-container">
                <ReactMarkdown>{quote.body}</ReactMarkdown>
              </div>
            </ListGroup.Item>);
          })}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
}

export default PinnedQuotes;
