import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import quotesReducer from '../reducers/quotes';
import rootReducer from '../reducers/root';


const store = configureStore({
  reducer: {
    quotes: quotesReducer,
    root: rootReducer
  },
});

export default () => {
  return store;
};


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

