import { createAction, createReducer } from '@reduxjs/toolkit';

const quotes = createAction<any, 'quotes/quotes'>('quotes/quotes');
const pinnedQuotes = createAction<any, 'quotes/pinned_quotes'>('quotes/pinned_quotes');
const addQuote = createAction<any, 'quotes/add_quote'>('quotes/add_quote');
const editQuote = createAction<any, 'quotes/edit_quote'>('quotes/edit_quote');
const didAddQuote = createAction<any, 'quotes/did_add_quote'>('quotes/did_add_quote');
const getQuote = createAction<any, 'quotes/quote'>('quotes/quote');
const didPinQuote = createAction<any, 'quotes/did_pin_quote'>('quotes/did_pin_quote');
const didUnPinQuote = createAction<any, 'quotes/did_unpin_quote'>('quotes/did_unpin_quote');



const initialState = {
  quotes: [],
  pinnedQuotes: [],
  didAddQuote: false,
  didGetQuotes: false,
  quote: null as any
};

const quotesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(quotes, (state, action) => {
      // eslint-disable-next-line no-param-reassign
      // state.quotes = state.quotes.concat(action.payload);
      state.quotes = action.payload;
      // state.didFetch = true;
      state.didGetQuotes = true;
    })
    .addCase(pinnedQuotes, (state, action) => {
      // eslint-disable-next-line no-param-reassign
      // state.quotes = state.quotes.concat(action.payload);
      state.pinnedQuotes = action.payload;
    })
    .addCase(addQuote, (state, action) => {
      // called directly from AddQuote component when a new quote is added.
      // No longer called from quotes action creator after response from API

      // This line will make sure it's immediately shown to the user once created
      state.quote = action.payload;


      // we use this to make sure the created quote is added to the
      // unshown set and reshowed to the user in the same session it was created.
      // state.didAddQuote = true is simply used to update the set of available indices
      // to include the index (last position in list of all quotes, visted and unvisited)
      // of the newly added quote
      // state.quotes.push(action.payload as never);
      // state.didAddQuote = true;
    })
    .addCase(editQuote, (state, action) => {
      state.quote = action.payload;
    })
    .addCase(didAddQuote, (state, action) => {
      // eslint-disable-next-line no-param-reassign
      // This is simply used to reset the state state of did add quote to false after
      // we've added a quote
      // so we're not continously adding to the set of available indices with each
      // component rerender.
      state.didAddQuote = action.payload;
    })
    .addCase(didPinQuote, (state, action) => {
      state.pinnedQuotes.unshift(action.payload as never);
    })
    .addCase(didUnPinQuote, (state, action) => {
      // TODO: Need to understand how to access the state variables so they can be used to
      // update the state
    })
    .addCase(getQuote, (state, action) => {
      state.quote = action.payload;
    });
});

export default quotesReducer;
