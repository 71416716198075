import { createAction, createReducer } from '@reduxjs/toolkit';

const fetching = createAction<any, 'root/fetching'>('root/fetching');
// const didFetch = createAction<any, 'root/did_fetch'>('root/did_fetch');

const initialState = {
  fetching: false
};

const rootReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetching, (state, action) => {
      // eslint-disable-next-line no-param-reassign
      // state.quotes = state.quotes.concat(action.payload);
      state.fetching = action.payload;
    });
});

export default rootReducer;
