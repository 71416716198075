export const awsconfig = {
  Auth: {
    userPoolId: 'us-east-1_vxxanOs5x',
    region: 'us-east-1',
    userPoolWebClientId: '6bnfcpv99iusqoib1rip2e0p5m',
    // identityPoolId: 'your_identity_pool_id',
    oauth: {
      domain: 'https://mindmantle.auth.us-east-1.amazoncognito.com',
      redirectSignIn: `${window.location.origin}/`,
      redirectSignOut: 'http://localhost:3000/',
      clientId: '6bnfcpv99iusqoib1rip2e0p5m',
      responseType: 'code'
    },
  },
};

// export const apiUrl = 'https://q2gwizpnoe.execute-api.us-east-1.amazonaws.com';

export const apiUrl = 'https://tpgt6arskb.execute-api.us-east-1.amazonaws.com';
