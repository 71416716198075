import React, { useState, useEffect, ReactElement } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/config/configureStore';
import { Spinner } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';


import AddQuote from '../AddQuote/AddQuote';
import { getQuotes, getQuote, deleteQuote, addQuote } from '../../store/actions/quotes';

let availableIndices: Set<number> = new Set();
let visited: number[] = [];

const authorSet: Set<string> = new Set();
const categorySet: Set<string> = new Set();
const sourceSet: Set<string> = new Set();
let authors: Array<string> = [];
let categories:  Array<string> = [];
let sources:  Array<string> = [];




function getNextIndex(): number {
  const items: number[] = Array.from(availableIndices);
  const nextIndex: number = items[Math.floor(Math.random() * items.length)];
  availableIndices.delete(nextIndex);
  visited.push(nextIndex);
  return nextIndex;
}


let quotesLength = 0;
// const msg = new SpeechSynthesisUtterance();

function App(): ReactElement {
  const dispatch = useAppDispatch();
  const fetching = useAppSelector((state: RootState) => state.root.fetching);

  const quotes = useAppSelector((state: RootState) => state.quotes.quotes);
  const didAddQuote = useAppSelector((state: RootState) => state.quotes.didAddQuote);
  const didGetQuotes = useAppSelector((state: RootState) => state.quotes.didGetQuotes);
  const quote = useAppSelector((state: RootState) => state.quotes.quote);
  const [showMenu, setShowMenu] = useState(false);

  if (quotes.length) {
    quotes.forEach((q) => {
      const { author, category, source } = q;
      authorSet.add(author);
      categorySet.add(category);
      sourceSet.add(source);
    });
    authors = Array.from(authorSet);
    categories = Array.from(categorySet);
    sources = Array.from(sourceSet);

  }

  const [editing, setEditing] = useState(false);

  if (didAddQuote) {
    quotesLength += 1;
    availableIndices.add(quotesLength - 1);
    dispatch({ type: 'quotes/did_add_quote', payload: false });
  }

  const onClickNext = () => {
    const nextIndex = getNextIndex();
    if (nextIndex === undefined) {
      // Make sure this is prompted by user otherwise infinite loop.
      const option = window.confirm('You have reviewed all your quotes. Would you like to start over?');
      if (option) {
        // reset()
        window.location.reload();
      } else {
        // user clicked Cancel
        // do nothing or perform some other action
      }
      return;
    }
    dispatch(getQuote(quotes[nextIndex]['quoteId']));
  };

  const onDeleteQuote = () => {
    dispatch(deleteQuote(quote.quoteId));
    // In the future, we may be able to implement his by saying. To use this approach,
    // we have to be sure that we are updating didAddQuote in the reducer after creating a
    // quote because the current quote could be a created quote.
    // visited.pop();
    // dispatch(deleteQuote(quote.quoteId));
    // onClickNext();
  };

  const reset: ()=> void = () => {
    console.log('...resetting');
    quotesLength = quotes.length;
    const setValues: number[] = [...Array(quotesLength).keys()];
    availableIndices = new Set(setValues);
    visited = [];
    onClickNext();
  };

  const [showModal, setShowModal] = useState(false);
  // make sure quote is null otherwise it will reset infinitely when lenght of quotes is 1
  if (quotes.length  && availableIndices.size === 0 && quote === null) {
    reset();
  }

  useEffect(() => {
    dispatch(getQuotes());
  }, []);

  // Note: Clicking next after clicking previous does not give you the next in the visisted sequence,
  // It simply gets the next unvisited item.
  const onClickPrevious = () => {
    const positionOfPreviousIndexInVisited = visited.length - 2;
    if (positionOfPreviousIndexInVisited >= 0) {
      const indexOfPrevious = visited[positionOfPreviousIndexInVisited];
      visited.pop();
      dispatch(getQuote(quotes[indexOfPrevious]['quoteId']));
      return;
    }
    onClickNext();
  };

  const pinUnpinQuote = () => {
    const newQuote = {...quote, pinned: quote.pinned ? undefined : 'pinned'};
    dispatch({type: 'quotes/edit_quote', payload: newQuote});
    dispatch(addQuote(newQuote));
    if (newQuote.pinned) {
      dispatch({type: 'quotes/did_pin_quote', payload: newQuote});
    } else {
      dispatch({type: 'quotes/did_unpin_quote', payload: newQuote});
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className='mt-2 mb-2' style={{height: '20px'}}>
        {
          fetching && <Spinner animation="grow" size='sm'/>
        }
      </div>

      <AddQuote
        showModal={showModal}
        closeModal={() => { setShowModal(false); setEditing(false); }}
        quote={editing ? quote : undefined}
        editing={editing}
        authors={authors}
        categories={categories}
        sources={sources}
      />
      {
        // Note this should be fine because even with did didGetQuotes && quotes.length && !quote (we deleted the quote),
        // we should call onClickNext to restore the next quote before rendering the button. Even if we deleted all the quotes, we shouldn't render the
        // button. We should let the user know and prompt a refresh which would then render the button.
        didGetQuotes && !quotes.length && !quote &&
          <button type="button" className="btn btn-secondary w-100" onClick={() => { setShowModal(true); }}>Add Quote</button>
      }
      {
        quote && <div className="card w-100" onClick={() => { if (showMenu) { setShowMenu(false); }}}>
          <div className='card-header d-flex justify-content-between'>
            <h3>{quote.category}</h3>
            <div className='d-flex flex-column align-items-end'>
              <button onClick={() => {setShowMenu(!showMenu);}} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <i className="bi bi-three-dots-vertical"></i>
              </button>
              <div className={`collapse position-absolute card navbar-collapse p-3 ${showMenu ? 'show' : ''}`} id="navbarCollapse">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <a className="nav-link" href="#" onClick={() => { pinUnpinQuote(); }}>{quote.pinned ? 'unpin' : 'pin'}</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" onClick={() => { setEditing(true); setShowModal(true); }}>Edit</a>
                  </li>
                  <li className="nav-item text-danger">
                    <a className="nav-link" href="#" onClick={onDeleteQuote}>Delete</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card-body">
            <h5 className="card-title mb-3"><small className='text-muted'>from </small>{quote.source}</h5>
            <h5><small className='text-muted'>by </small>{quote.author}</h5>
            {/* <h6 className="card-subtitle  mb-2">by {quote.author}</h6> */}
            <p className="lead" style={{height: '50vh', overflowY: 'auto', whiteSpace: 'pre-line'}}><ReactMarkdown>{quote.body}</ReactMarkdown></p>
          </div>
          <div className="card-footer d-flex justify-content-between">
            {/* <button type="button" className="btn-" onClick={onClickPrevious}><i className="bi bi-arrow-left-circle"></i></button> */}
            <button type="button" className="btn btn-lg" onClick={onClickPrevious} style={{ padding: '10px 20px' }}>
              <i className="bi bi-arrow-left-circle" style={{ fontSize: '24px' }}></i>
            </button>
            <button type="button" className="btn btn-lg" style={{ padding: '10px 20px' }} onClick={() => { setShowModal(true); }}><i className="bi bi-plus-circle" style={{ fontSize: '24px' }}></i></button>
            <button type="button" className="btn btn-lg" style={{ padding: '10px 20px' }} onClick={onClickNext}><i className="bi bi-arrow-right-circle" style={{ fontSize: '24px' }}></i></button>
          </div>
        </div>
      }
    </div>
  );
}

export default App;
